//
import PCategoryService from '@/services/api/Provider/PCategory'
import PSubcategoryService from '@/services/api/Provider/PSubcategory'
import RulesService from '@/services/rules'

//
export default {
  name: 'v-view-account-categories',
  data() {
    return {
      busy: {
        create: false,
        delete: false,
        listed: false,
        update: false,   
      },
      dialogs: {
        create: false,
        delete: false,
        update: false,
      },
      filters: {
        limit: 15,
        page: 1,
      },
      pCategories: {
        data: [],
        last_page: 1,
      },
      pSubcategoryCreate: {},
      pSubcategoryDelete: {},
      pSubcategoryStored: {},
      pSubcategoryUpdate: {},
      pSubcategories: {
        data: [],
        last_page: 1,
      },
    }
  },
  methods: {

    /**
     * 
     */
    async getCategories() {
      this.pCategories = await PCategoryService.getAllPaginate()
    },

    /**
     * 
     */
    handleCreateReset() {
      this.pSubcategoryCreate = PSubcategoryService.toCreate()
    },

    /**
     * 
     */
    async handleCreateSubmit() {
      this.busy.create = true
      await PSubcategoryService.create(this.pSubcategoryCreate).then(this.handleCreateSuccess).catch(this.handleError)
      this.busy.create = false
    },

    /**
     * 
     */
    handleCreateSuccess(payload) {
      this.handleReset()
      this.handleCreateReset()
      PSubcategoryService.translate({ response: payload }).then(this.$toast.success)
      this.dialogs.create  = false
    },

    /**
     * 
     */
    handleDeleteClick(pSubcategory) {
      this.dialogs.delete   = true
      this.pSubcategoryDelete  = pSubcategory
    },

    /**
     * 
     */
    async handleDeleteSubmit() {
      this.busy.delete = true
      await PSubcategoryService.delete(this.pSubcategoryDelete).then(this.handleDeleteSuccess).catch(this.handleError)
      this.busy.delete = false
    },

    /**
     * 
     */
    handleDeleteSuccess() {
      this.handleReset()
      this.dialogs.delete = false
    },

    /**
     * 
     */
    async handleReset() {
      this.busy.listed = true
      this.pSubcategories = await PSubcategoryService.getBy(this.filters)
      this.busy.listed = false
    },

    /**
     * 
     */
    handleUpdateClick(pSubcategory) {
      this.dialogs.update   = true
      this.pSubcategoryUpdate  = { ...pSubcategory }
    },

    /**
     * 
     */
    handleUpdateReset() {
      this.pSubcategoryUpdate  = { ...this.pSubcategoryStored }
    },

    /**
     * 
     */
    async handleUpdateSubmit() {
      this.busy.update = true
      await PSubcategoryService.update(this.pSubcategoryUpdate).then(this.handleUpdateSuccess).catch(this.handleError)
      this.dialogs.update   = false
      this.busy.update = false
    },

    /**
     * 
     */
    handleUpdateSuccess(payload) {
      PSubcategoryService.translate({ response: payload }).then(this.$toast.success)
      this.handleReset()
    },

    /**
     * 
     */
    handleError(payload) {
      PSubcategoryService.translate(payload).then(this.$toast.error)
    },
  },
  created() {
    this.handleReset()
    this.getCategories()
  },
  props: {

    /**
     * 
     */
    categoryRules: {
      type: Array,
      default: () => [
        RulesService.required,
      ],
    },

    /**
     * 
     */
    nameRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.max(200),
      ],
    },
  },
  watch: {

    /**
     * 
     */
    'filters.page'() {
      this.handleReset()
    },

    /**
     * 
     */
    'filters.limit'() {
      this.handleReset()
    },
  },
}